<template>
	<section class="section wow">
		<div class="content">
			<div class="error-page-block">
				<div class="error-page-block-content" />
			</div>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import Events from '@/api/events';

export default {
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
        })
    },
    created() {
        if (this.$route.query.redirect_url !== null) {
            this.generateURL();
            localStorage.setItem('redirectURL', this.$route.query.redirect_url)
        }
        else this.$router.push('/events/phystech')
    },
    methods: {
        generateURL() {
            if (this.isLogged) {
                let redirect =  localStorage.getItem('redirectURL');
                Events.getAccessToken().then((response) => {
                    if (response.data.access_token)
                        window.location.href = redirect + '?access_token=' + response.data.access_token
                })
            } else this.modalOpen('regIn', 'test')
        }
    }
}
</script>
